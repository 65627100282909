@font-face {
  font-family: 'FWDCircularTT-Black';
  src: url(./FWDCircularTT-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-BlackItalic';
  src: url(./FWDCircularTT-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-Bold.';
  src: url(./FWDCircularTT-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-BoldItalic';
  src: url(./FWDCircularTT-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-Book';
  src: url(./FWDCircularTT-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-BookItalic';
  src: url(./FWDCircularTT-BookItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-Medium';
  src: url(./FWDCircularTT-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'FWDCircularTT-MediumItalic';
  src: url(./FWDCircularTT-MediumItalic.ttf) format('truetype');
}
