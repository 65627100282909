@keyframes moveDownAndFadeInTop {
  0% {
    opacity: 0;
    top: 30px;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    top: 72px;
    pointer-events: auto;
  }
}

.nxtg-header-wrapper {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  transform: translateY(0%);
  transition: transform 400ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  font-family: FWDCircularWeb, sans-serif;
}

.nxtg-header-wrapper:before {
  top: 0;
  left: 4.4375rem;
  height: 72px;
  right: 0;
  content: close-quote;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  background-color: white;
  border-bottom-left-radius: 16px;
}
.nxtg-header-wrapper.nxtg-header-fluid:before {
  left: 0;
  border-bottom-left-radius: 0;
}
.nxtg-header-wrapper.nxtg-header-scrolled {
  transform: translateY(-100%);
}
.nxtg-header-wrapper.nxtg-header-scrolled:before {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.nxtg-hamburger-icon {
  width: 71px;
  height: 71px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.nxtg-left-content {
  padding-left: 32px;
  z-index: 0;
  transition: paddingLeft 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  display: inline-flex;
  transition: height 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  align-items: center;
  justify-content: center;
}

.nxtg-header-links-wrapper .nxtg-header-links-primary-secondary {
  display: flex;
  align-items: center;
}
.nxtg-header-dialoag-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.nxtg-header-logo a {
  width: 96px;
  height: 33px;
  display: inline-flex;
  visibility: visible;
  cursor: pointer;
}
.nxtg-primary-link .nxtg-primary-link-icon {
  display: none;
}
.nxtg-primary-link ul {
  list-style: none;
  padding: 0;
  height: 100%;
  align-items: center;
  padding-left: 32px;
  flex-direction: row;
  display: flex;
}
.nxtg-primary-link ul li {
  margin-left: 20px;
}
.nxtg-primary-link ul li:first-child {
  margin-left: 0px;
}
.nxtg-primary-link ul li a {
  font-family: FWDCircularWeb, sans-serif;
  color: #183028;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.25rem;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: background-color, color, border-color;
}
.nxtg-primary-link ul li a:hover {
  color: #e87722;
}
.nxtg-secondary-link {
  height: 100%;
  border-top: none;
  margin-top: 0;
  align-items: center;
  margin-left: 20px;
  padding-top: 0;
  padding-left: 20px;
  flex-direction: row;
  display: flex;
  position: relative;
}
.nxtg-secondary-link:before {
  left: 0;
  width: 1px;
  content: close-quote;
  position: absolute;
  height: 20px;
  background-color: #dbdfe1;
  top: 50%;
  transform: translateY(-50%);
}
.nxtg-secondary-link ul {
  list-style: none;
  padding: 0;
  height: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.nxtg-secondary-link ul li {
  margin-left: 20px;
}
.nxtg-secondary-link ul li:first-child {
  margin-left: 0px;
}
.nxtg-secondary-link ul li a {
  font-family: FWDCircularWeb, sans-serif;
  color: #636566;
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
  line-height: 1.25rem;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: background-color, color, border-color;
}
.nxtg-secondary-link ul li a:hover {
  color: #e87722;
}
.nxtg-right-content {
  top: 0;
  right: 0;
  display: flex;
  position: absolute;
  margin-right: 16px;
}
.nxtg-right-content > div {
  display: inline-flex;
  transition: height 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  align-items: center;
  justify-content: center;
  height: 72px;
}
.nxtg-primary-button {
  padding: 6px 16px;
  font-family: FWDCircularWeb, sans-serif;
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: box-shadow, border-color;
  color: #ffffff;
  border: none;
  opacity: 1;
  box-shadow: none;
  background-color: #e87722;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.6px;
  text-transform: none;
  cursor: pointer;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 4px;
  text-decoration: none;
}
.nxtg-primary-button:hover {
  color: #e87722;
  border: none;
  opacity: 1;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.nxtg-contact-button-container {
  margin-left: 20px;
}
.nxtg-contact-button svg {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.nxtg-contact-button svg {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.nxtg-hamburger-icon .nxtg-inner-lines {
  cursor: pointer;
  transform: scale(0.6);
  display: none;
}
.nxtg-hamburger-icon-active {
  background-color: #e87722;
}

.nxtg-hamburger-icon .nxtg-inner-lines > div {
  background-color: #ffffff;
  opacity: 1;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: left center;
  border-radius: 1px;
}
.nxtg-header-fluid .nxtg-hamburger-icon .nxtg-inner-lines > div {
  background-color: #e87722;
}
.nxtg-hamburger-icon .nxtg-line1 {
  width: 32px;
  height: 4px;
}
.nxtg-hamburger-icon .nxtg-line2 {
  width: 40px;
  height: 4px;
  margin-top: 8px;
}
.nxtg-hamburger-icon .nxtg-line3 {
  width: 24px;
  height: 4px;
  margin-top: 8px;
  border-bottom-right-radius: 3px;
}
.nxtg-hamburger-open .nxtg-hamburger-icon .nxtg-line1 {
  width: 34px;
  transform: translateX(8px) rotate(45deg);
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.nxtg-hamburger-open .nxtg-hamburger-icon .nxtg-line2 {
  opacity: 0;
}
.nxtg-hamburger-open .nxtg-hamburger-icon .nxtg-line3 {
  width: 34px;
  transform: translateX(8px) rotate(-45deg);
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 0;
}
.nxtg-hamburger-open .nxtg-hamburger-icon .nxtg-inner-lines > div {
  background-color: #e87722;
}
.nxtg-footer-wrapper {
  font-family: FWDCircularWeb, sans-serif;
  background-color: #fef9f4;
  position: relative;
}
.nxtg-footer-container:before {
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #e87722;
  content: '';
  position: absolute;
  box-sizing: border-box;
}
.nxtg-footer-container {
  max-width: 1288px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 96px 32px 16px;
  box-sizing: border-box;
}
.nxtg-footer-top {
  width: calc(100% + 24px);
  margin: -12px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.nxtg-full-line hr {
  background-color: #dbdfe1;
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
}
.nxtg-full-social-line hr {
  margin: 24px 0px 24px;
  width: 60%;
}
.nxtg-footer-bottom {
  margin-top: 64px;
}
.nxtg-footer-bottom-wrapper {
  display: flex;
  justify-content: space-between;
}
.nxtg-footer-quick-links {
  max-width: 600px;
  flex-basis: 50%;
  margin-top: 16px;
}
.nxtg-footer-quick-links ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.nxtg-footer-quick-links ul li {
  display: inline-block;
}
.nxtg-footer-quick-links ul li:after {
  color: rgb(99, 101, 102);
  margin: 0px 8px;
  content: '|';
}
.nxtg-footer-quick-links ul li:last-child::after {
  margin: 0px;
  content: '';
}
.nxtg-footer-quick-links ul li a {
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: background-color, color, border-color;
  text-decoration: none;
  cursor: pointer;
  color: #636566;
  font-family: FWDCircularWeb, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}
.nxtg-footer-quick-links ul li a:hover {
  color: #e87722;
}
.nxtg-copyright-text {
  max-width: 600px;
  flex-basis: 50%;
  margin-top: 16px;
  text-align: right;
}
.nxtg-copyright-text p {
  font-family: FWDCircularWeb, sans-serif;
  margin: 0;
  display: inline-block;
  color: #636566;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}
.nxtg-footer-logo-content svg {
  width: 6.5625rem;
  height: 2.25rem;
  max-width: 6.5625rem;
  max-height: 2.25rem;
}
.nxtg-country-wrapper {
  display: inline-flex;
  line-height: 1.25rem;
  vertical-align: middle;
  font-family: FWDCircularWeb, sans-serif;
  margin-top: 28px;
  margin-bottom: 88px;
}
.nxtg-country-label {
  color: #183028;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.00938em;
}
.nxtg-footer-call-icon {
  margin-right: 8px;
  color: #ffffff;
  background-color: #e87722;
  display: inline-block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-property: background-color, color, border-color !important;
  border-radius: 40px;
  width: 40px;
  height: 40px;
}
.nxtg-footer-call-icon:hover {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: background-color, color, border-color;
}
.nxtg-footer-call-icon svg {
  width: 24px;
  height: 24px;
  color: #ffffff;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.nxtg-footer-call-icon svg path {
  fill: #ffffff;
}
.nxtg-footer-call-icon:hover svg path {
  fill: #e87722;
}
.nxtg-footer-contact-wrapper {
  margin-top: 0;
}
.nxtg-footer-contact-wrapper > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: background-color, color, border-color;
  margin-bottom: 16px;
}
.nxtg-footer-call-text {
  font-family: FWDCircularWeb, sans-serif;
  font-weight: 700;
  margin: 0;
  color: #183028;
  font-size: 1.5625rem;
  line-height: 1.9375rem;
  letter-spacing: 0.00735em;
}
.nxtg-footer-social-wrapper ul {
  padding: 0;
  max-width: calc(288px + 12px);
  list-style: none;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
}
.nxtg-footer-social-wrapper ul li {
  height: 40px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.nxtg-footer-social-wrapper ul li img {
  width: 40px;
  height: auto;
}
.nxtg-footer-left-content {
  padding: 12px;
  min-height: 280px;
  position: relative;
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  box-sizing: border-box;
}
.nxtg-footer-right-content {
  padding: 12px;
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  box-sizing: border-box;
}
.nxtg-footer-links-wrapper {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: flex-end;
}
.nxtg-footer-links-wrapper .nxtg-footer-links-column {
  flex-grow: 1;
  max-width: 208px;
  padding-right: 24px;
  flex-basis: 25%;
  box-sizing: border-box;
}
.nxtg-footer-links-wrapper .nxtg-footer-links-column:last-child {
  max-width: 184px;
  padding-right: 0;
}
.nxtg-footer-links-column ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nxtg-footer-links-column ul a {
  line-height: 1.25rem;
  color: #183028;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.00938em;
  display: inline-block;
  word-break: break-word;
  margin-bottom: 12px;
  font-family: FWDCircularWeb, sans-serif;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-property: background-color, color, border-color !important;
  text-decoration: none;
  cursor: pointer;
}
.nxtg-footer-links-column ul a:hover {
  color: #e87722;
}
.nxtg-footer-links-column ul a {
  margin-bottom: 0;
}
.nxtg-footer-links-column ul a.nxtg-link-highlight {
  color: #e87722;
  display: inline-flex;
  padding: 4px 4px;
  margin-left: -4px;
  font-weight: 700;
}
.nxtg-footer-links-column ul li,
.nxtg-accordion-trigger {
  margin-bottom: 12px;
}
.nxtg-footer-links-column ul a.nxtg-link-highlight:hover {
  border-radius: 4px;
  background-color: rgba(232, 119, 34, 0.2);
}

.nxtg-popover-wrapper {
  position: relative;
}
.nxtg-popover-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  top: 72px;
  max-width: 240px;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
}
.nxtg-popover-content-right {
  left: 90%;
  transform: translateX(-90%);
}
.nxtg-popover-content:before {
  position: absolute;
  z-index: -1;
  content: '';
  right: calc(50%);
  top: -4px;
  width: 8px;
  height: 8px;
  margin: auto;
  content: '';
  display: block;
  transform: rotate(45deg);
  background: #fff;
  box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.12);
}
.nxtg-popover-content-right:before {
  right: calc(20%);
}
.nxtg-popover-wrapper:hover .nxtg-popover-content {
  animation: moveDownAndFadeInTop 0.6s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  z-index: 10;
  opacity: 1;
  visibility: visible;
}
.nxtg-popover-content ul {
  list-style: none;
  padding: 8px 0;
  margin: 0;
}
.nxtg-popover-content ul li {
  margin: 0;
}
.nxtg-popover-content ul li a {
  padding: 12px;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #183028;
  font-size: 1rem;
  font-family: FWDCircularWeb, sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.00938em;
  cursor: pointer;
}
.nxtg-popover-content ul li a svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: inline-block;
  box-sizing: border-box;
}
.nxtg-popover-content ul li a > span {
  height: 24px;
  display: inline-flex;
  align-items: center;
}
.nxtg-popover-content ul li a > span:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: max-content;
}
.nxtg-popover-content ul li a svg path {
  fill: #e87722;
}
.nxtg-popover-content ul li a:hover {
  background-color: #e87722;
  color: #ffffff;
}
.nxtg-popover-content ul li a:hover svg path {
  fill: #ffffff;
}
.nxtg-country-dropmenu-container {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.nxtg-country-dropmenu-container > span {
  font-weight: 700;
  line-height: 1.25rem;
  padding-left: 4px;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  box-sizing: content-box;
  color: #e87722;
  font-size: 16px;
  display: inline-flex;
}
.nxtg-country-dropmenu-container > svg {
  fill: #e87722;
  width: 16px;
  height: 16px;
  padding-top: 2px;
}
.nxtg-dropdown-wrapper {
  position: relative;
}
.nxtg-dropdown-content {
  opacity: 0;
  /* visibility: hidden; */
  position: fixed;
  right: 0;
  width: auto;
  top: 22px;
  max-width: 240px;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 9999;
  overflow: auto;
}
.nxtg-dropdown-wrapper.nxtg-dropdown-open .nxtg-dropdown-content {
  position: absolute;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: opacity, transform;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 142px 0px;
}
.nxtg-dropdown-content ul {
  list-style: none;
  padding: 8px 0;
  margin: 0;
}
.nxtg-dropdown-content ul li {
  margin: 0;
}
.nxtg-dropdown-content ul li a {
  padding: 8px 40px 8px 16px;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #183028;
  font-size: 1rem;
  font-family: FWDCircularWeb, sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 0.00938em;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.nxtg-dropdown-content ul li a svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.nxtg-dropdown-content ul li a > span {
  height: 24px;
  display: inline-block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nxtg-dropdown-content ul li a svg path {
  fill: #e87722;
}
.nxtg-dropdown-content ul li a:hover {
  background-color: #e87722;
  color: #ffffff;
}
.nxtg-dropdown-content ul li a:hover svg path {
  fill: #ffffff;
}
.nxtg-header-country-wrapper-mobile {
  display: none;
}
.nxtg-footer-social-wrapper-mobile,
.nxtg-footer-country-wrapper-mobile {
  display: none;
}
.nxtg-telephone-text-wrapper {
  align-items: center;
}
.nxtg-telephone-text-wrapper a {
  color: #183028;
  padding: 4px;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 4px;
}
.nxtg-telephone-text-wrapper a:hover {
  color: #e87722;
}
.nxtg-telephone-tooltip-icon {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
}
.nxtg-telephone-tooltip-label-text {
  font-size: 14px;
  line-height: 21px;
  color: #636566;
  font-weight: 300;
  text-align: left;
}
.nxtg-telephone-tooltip-label-text p {
  margin: 0;
}
.nxtg-telephone-tooltip-label-text a {
  color: #183028;
  padding: 4px;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 4px;
}
.nxtg-telephone-tooltip-label-text a:hover {
  color: #e87722;
}
.nxtg-telephone-tooltip-icon svg {
  width: 16px;
  height: 16px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.nxtg-telephone-tooltip-icon:hover svg path {
  fill: #e87722;
}
.nxtg-telephone-tooltip-icon:hover svg path:nth-child(3),
.nxtg-telephone-tooltip-icon:hover svg path:nth-child(4) {
  fill: #ffffff;
}
.nxtg-tooltip-content {
  text-align: left;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  top: 16px;
  max-width: 240px;
  background: #fef9f4;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  z-index: 9999;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.nxtg-tooltip-content-top {
  bottom: 16px;
  top: auto;
}
.nxtg-tooltip-content .nxtg-tooltip-content-text {
  display: inline-flex;
}
.nxtg-tooltip-content .nxtg-diamond-arrow {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 4px);
  top: -4px;
  width: 8px;
  height: 8px;
  margin: auto;
  content: '';
  display: block;
  transform: rotate(45deg);
  background: #fef9f4;
  box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.12);
}
.nxtg-tooltip-content-top .nxtg-diamond-arrow {
  top: auto;
  bottom: -4px;
  z-index: -1;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.12);
}
.nxtg-tooltip-wrapper:hover .nxtg-tooltip-content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  position: absolute;
}
@media (max-width: 1289px) {
  @keyframes moveDownAndFadeInTop {
    0% {
      opacity: 0;
      top: 20px;
      pointer-events: none;
    }
    100% {
      opacity: 1;
      top: 56px;
      pointer-events: auto;
    }
  }
  .nxtg-popover-content {
    top: 56px;
  }
  .nxtg-popover-content-right:before {
    right: calc(15%);
  }
  .nxtg-hamburger-icon .nxtg-inner-lines {
    display: inline-block;
  }
  .nxtg-hamburger-icon {
    width: 56px;
    height: 56px;
    z-index: 10;
  }
  .nxtg-header-wrapper:before {
    left: 56px;
    height: 56px;
    z-index: 9;
  }
  .nxtg-hamburger-open .nxtg-header-wrapper.nxtg-header-fluid {
    transform: translateY(0%);
  }
  .nxtg-hamburger-open .nxtg-header-wrapper.nxtg-header-fluid:before {
    left: 56px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 16px;
  }

  .nxtg-header-logo a {
    width: 56px;
    height: 1.203125rem;
  }
  .nxtg-primary-link .nxtg-primary-link-icon {
    display: inline-flex;
  }
  .nxtg-primary-link .nxtg-primary-link-icon svg {
    width: 24px;
    height: 24px;
  }
  .nxtg-primary-link .nxtg-primary-link-icon svg path {
    fill: #e87722;
  }
  .nxtg-primary-link ul,
  .nxtg-primary-link ul li {
    margin: 0;
    display: block;
    padding: 0;
  }
  .nxtg-primary-link ul li a {
    display: inline-flex;
    align-items: flex-start;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5625rem;
    letter-spacing: normal;
    margin-top: 16px;
    min-height: 32px;
    text-align: left;
  }
  .nxtg-primary-link ul li:first-child a {
    margin-top: 0;
  }
  .nxtg-primary-link ul li a .nxtg-primary-link-icon {
    margin-right: 16px;
  }
  .nxtg-secondary-link {
    display: block;
    position: relative;
    border-top: 1px solid #dbdfe1;
    margin-top: 24px;
    margin-left: 0;
    padding-top: 24px;
    padding-left: 0;
    flex-direction: column;
    height: auto;
  }
  .nxtg-secondary-link:before {
    content: none;
  }
  .nxtg-secondary-link ul {
    display: block;
    padding: 0;
    margin: 0;
  }
  .nxtg-secondary-link ul li {
    margin: 16px 0 0;
  }
  .nxtg-secondary-link ul li:first-child {
    margin: 0;
  }
  .nxtg-secondary-link ul li a {
    line-height: 1.5rem;
  }
  .nxtg-left-content {
    padding-left: 16px;
    z-index: initial;
  }
  .nxtg-header-logo {
    z-index: 10;
  }
  .nxtg-right-content {
    z-index: 10;
  }
  .nxtg-header-links-wrapper {
    position: fixed;
    width: 100%;
    display: block;
    background: #fff;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 8;
    box-sizing: border-box;
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: translateX(-100%);
    opacity: 1;
  }
  .nxtg-hamburger-open {
    overflow: hidden;
  }
  .nxtg-hamburger-open .nxtg-header-links-wrapper {
    display: block;
    transform: translateX(0%);
    opacity: 1;
  }
  .nxtg-header-links-wrapper .nxtg-header-links-primary-secondary {
    display: block;
    padding: 128px 56px;
    width: 100%;
    overflow: auto;
  }
  .nxtg-contact-button svg {
    width: 24px;
    height: 24px;
  }
  .nxtg-right-content > div {
    height: 56px;
  }
  .nxtg-primary-button {
    color: #e87722;
    background-color: transparent;
    padding: 0 4px;
    border: 4px solid transparent;
    margin-left: -8px;
    margin-right: -8px;
  }
  .nxtg-primary-button:hover {
    color: #e87722;
    box-shadow: none;
    border: 4px solid transparent;
    background-color: transparent;
  }
  .nxtg-header-country-wrapper-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    border-top: 1px solid #dbdfe1;
    justify-content: space-between;
    width: 100%;
    z-index: 1001;
    background: #ffffff;
    box-sizing: border-box;
  }
  .nxtg-hamburger-open .nxtg-header-country-wrapper-mobile {
    display: block;
  }
  .nxtg-header-country-wrapper-mobile .nxtg-country-wrapper {
    display: flex;
    margin: 0;
    min-height: 24px;
  }
  .nxtg-header-country-wrapper-mobile .nxtg-country-label {
    color: #636566;
    line-height: 21px;
    font-size: 14px;
    font-weight: 300;
  }
  .nxtg-header-country-wrapper-mobile .nxtg-country-dropmenu-container > span {
    font-size: 14px;
    color: #183028;
    line-height: 21px;
  }
}
@media (max-width: 961px) {
  .nxtg-dropmenu-country-mobile {
    display: inline-block;
  }
  .nxtg-footer-call-small-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .nxtg-footer-container {
    max-width: 720px;
    padding: 48px 16px 16px;
  }
  .nxtg-footer-left-content,
  .nxtg-footer-right-content {
    max-width: 100%;
    flex-basis: 100%;
  }
  .nxtg-footer-left-content {
    display: flex;
    justify-content: space-between;
    min-height: auto;
  }
  .nxtg-footer-logo-content {
    width: 40%;
    display: inline-block;
  }
  .nxtg-country-wrapper,
  .nxtg-full-social-line,
  .nxtg-footer-social-wrapper-desktop,
  .nxtg-footer-bottom-full-line,
  .nxtg-footer-country-wrapper-desktop {
    display: none;
  }
  .nxtg-footer-contact-wrapper {
    display: inline-block;
    position: static;
    text-align: right;
    margin-top: 0;
  }
  .nxtg-footer-contact-wrapper > a {
    justify-content: flex-end;
  }
  .nxtg-footer-bottom-wrapper {
    display: block;
  }
  .nxtg-footer-social-wrapper-mobile,
  .nxtg-footer-country-wrapper-mobile {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 0;
  }
  .nxtg-footer-social-wrapper ul {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
    display: inline-block;
    text-align: center;
  }
  .nxtg-footer-quick-links,
  .nxtg-copyright-text {
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 0px;
    text-align: center;
  }
  .nxtg-copyright-text {
    margin-top: 40px;
  }
  .nxtg-copyright-text p,
  .nxtg-footer-quick-links ul li a {
    font-size: 14px;
    line-height: 21px;
  }
  .nxtg-copyright-text p.nxtg-breck-line {
    display: block;
  }
  .nxtg-footer-copyright-full-line {
    position: relative;
  }
  .nxtg-footer-copyright-full-line hr {
    top: 1.375rem;
    width: 100%;
    position: relative;
  }
  .nxtg-footer-links-wrapper .nxtg-footer-links-column {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
  .nxtg-footer-primary-link {
    display: flex;
    align-items: center;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .nxtg-footer-primary-link svg {
    width: 16px;
    height: 16px;
    font-size: 18px;
    margin-right: 4px;
  }
  .nxtg-accordion-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .nxtg-footer-links-column ul a.nxtg-link-highlight:before {
    content: ' ';
    /* background-image: url('../../asset/svg/arrow-right.svg'); */
    width: 20px;
    height: 16px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    margin-top: 2px;
  }
  .nxtg-accordion-trigger:after {
    content: ' ';
    /* background-image: url('../../assets/svg/plus.svg'); */
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    margin-top: 2px;
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .nxtg-accordion-open.nxtg-accordion-trigger:after {
    transform: rotate(180deg);
    /* background-image: url('../../asset/svg/minus.svg'); */
  }
  .nxtg-accordion-collapse {
    display: none;
    overflow: hidden;
  }
  .nxtg-footer-links-column ul.nxtg-accordion-collapse {
    padding-left: 20px;
  }
  .nxtg-accordion-collapse li {
    padding-bottom: 8px;
  }
  .nxtg-accordion-collapse li:last-child {
    padding-bottom: 16px;
  }
  .nxtg-footer-links-column ul li,
  .nxtg-accordion-trigger {
    margin-bottom: 0;
  }
  .nxtg-footer-links-wrapper .nxtg-footer-links-column:last-child {
    max-width: 100%;
    width: 100%;
  }
  .nxtg-footer-links-column > ul > li {
    border-bottom: 1px solid #dbdfe1;
  }
  .nxtg-footer-links-column ul a.nxtg-link-highlight {
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .nxtg-footer-contact-wrapper > a {
    margin-bottom: 0;
  }
  .nxtg-footer-right-content {
    padding-top: 0;
  }
  .nxtg-footer-left-content {
    padding-bottom: 16px;
  }
  .nxtg-telephone-text-wrapper {
    margin-top: 16px;
  }
  .nxtg-footer-bottom {
    margin-top: 48px;
  }
  .nxtg-telephone-tooltip-label-text {
    display: inline-block;
    text-align: right;
  }
}
